import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "アプリエンジニアがのぞいた React Native 〜メドレー TechLunch〜",
  "date": "2018-05-29T07:41:25.000Z",
  "slug": "entry/2018/05/29/164125",
  "tags": ["medley"],
  "hero": "./2018_05_29.png",
  "heroAlt": "react native"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部の高井です。メドレー開発本部で行われている勉強会「TechLunch」で`}<a parentName="p" {...{
        "href": "https://facebook.github.io/react-native/"
      }}>{`React Native`}</a>{`について発表しました。`}</p>
    <p>{`私は普段は Swift、Kotlin/Java を使ってネイティブアプリを開発しており、React Native に触るのは初めてでした。そこで今回は、アプリエンジニアの視点から、実装するための基本的な知識と弊社の実際の開発で使えそうかを検討した結果についてご紹介します。`}</p>
    <h1>{`なぜ React Native を触ってみようと思ったか`}</h1>
    <p>{`オンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」の開発では、iOS/Android アプリをそれぞれのネイティブ言語で別々に開発しているため、実装やレビューの際にはプラットフォーム間の仕様の違いを理解する必要があり、なかなか大変だと感じていました。`}</p>
    <p>{`これらの課題に対して`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2018/03/27/180621"
      }}>{`こちら`}</a>{`のブログでも紹介したような施策を行って改善を行ってきましたが、ソースを共通化することでより開発効率を向上できないかと思い、クロスプラットフォーム開発についても調べてみることにしました。`}</p>
    <p>{`その中でも以下の理由から、今回は React Native について調べてみることにしました。`}</p>
    <ul>
      <li parentName="ul">{`JavaScript（以下 JS）・ React のため、Web エンジニアがネイティブアプリ開発を行う際のハードルを低くすることができそう`}</li>
      <li parentName="ul">{`UI の実装にネイティブ UI を使用しているので自然なデザインやインタラクションを作りやすそう`}</li>
      <li parentName="ul">{`ある程度リリースから時間が経って情報が豊富にある`}</li>
    </ul>
    <p>{`特に弊社では、ネイティブアプリより Web 開発をメインに行ってきたエンジニアの方が多く、また Web フロントに React が使われているプロダクトもいくつかあるので、React Native を採用することでチームの開発効率の向上だけでなく、開発本部全体でもネイティブアプリ開発の学習コストが低くなるのではないかと考えました。`}</p>
    <p>{`そこで、以降ではネイティブアプリエンジニアと Web エンジニアそれぞれにとって、開発しやすいかどうかという観点で React Native の開発方法を見ていきたいと思います。`}</p>
    <h1>{`初期設定について`}</h1>
    <h2>{`インストール〜アプリ実行`}</h2>
    <p>{`インストールは公式の`}<a parentName="p" {...{
        "href": "https://facebook.github.io/react-native/docs/getting-started.html"
      }}>{`Getting Started`}</a>{`にもありますが、以下のコマンドで完了です。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`$ brew `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`node`}</span>{`
$ brew `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` watchman
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` -g react-native-cli
`}</code></pre></div>
    <p>{`今回、私が触るにあたっては Xcode と Android Studio のシミュレータ（エミュレータ）で実行しながら開発しましたが、React Native は Xcode や Android Studio がインストールされていなくても、`}<a parentName="p" {...{
        "href": "https://expo.io/"
      }}>{`Expo`}</a>{`というクライアントアプリを実機にインストールすることで画面をプレビューしながら開発することができます。`}</p>
    <p>{`これなら、Xcode のダウンロードを待つ必要もありません！（iOS エンジニアでもアップグレードのたびに Xcode のダウンロードを待つのはイライラします）`}</p>
    <p>{`次に、プロジェクト作成、シミュレータでのアプリ実行は以下のコマンドで実行できます。`}</p>
    <p>{`ただし、シミュレータ実行前に Xcode Command Line Tools のインストールと Android Studio でいくつかの設定（SDK のインストール、AVD の作成、環境変数の設定）が必要です。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# プロジェクト作成`}</span>{`
$ react-native init AwesomeProject
 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# アプリ実行（シミュレータ）`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` AwesomeProject
$ react-native run-ios or react-native run-android `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Android の場合、emulator を別途起動してからでないと実行できない`}</span></code></pre></div>
    <h1>{`実装してみた感想`}</h1>
    <h2>{`UI の実装方法`}</h2>
    <p>{`React Native では React 同様 UI の各パーツをコンポーネントと呼び、それらを配置することで UI を実装していきます。違いは Web の HTML の代わりに Native の UI を描画するためのコンポーネントとして使う点です。`}</p>
    <p>{`見た目やレイアウトは CSS と似たような形式で記述します。React Native で使えるスタイルのプロパティは各コンポーネントで異なりますが、例えば、View コンポーネントに設定できるプロパティには以下のようなものがあります。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://facebook.github.io/react-native/docs/view-style-props.html"
        }}>{`View Style Props`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://facebook.github.io/react-native/docs/layout-props.html"
        }}>{`Layout Props`}</a></li>
    </ul>
    <p>{`Web で使われているものと全く同じというわけではないですが、flex、margin、border などの使い慣れている CSS のプロパティ名で設定できるので、Web エンジニアにとっては実装のハードルが下がるのではないかと思いました。ただ、普段 CSS を触っていないネイティブアプリエンジニアにとっては学習コストがかかると思います。`}</p>
    <p>{`また一度ビルドすれば、JS による修正内容をビルドなしでシミュレータに反映することができるので、View 周りの調整は効率的にできそうでした。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// js/components/home.js`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`renderItem`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` item`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` index `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`View style`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`styles`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`row`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Text style`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`styles`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`title`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
             `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`parseInt`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`index`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
             `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`". "`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
             `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`item`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`title`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`Text`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
         `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Text style`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`styles`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`description`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`item`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`description`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`Text`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`View`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` styles `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` StyleSheet`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`create`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`row`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`borderBottomWidth`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`borderColor`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"#ccc"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`title`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`fontSize`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`15`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`fontWeight`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"600"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`description`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`marginTop`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`fontSize`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`ただ、OS ごとにデザインを合わせる方針にしない限りは、コードも別々になるところがわりと多くなりそうだと感じました。`}</p>
    <p>{`例えば、TabBar(iOS)と DrawerLayout(Android)、DatePicker(iOS)と TimePicker(Android)、ProgressView(iOS)と ProgressBar(Android)などは React Native では別コンポーネントとして提供されていて、API も違っていました。`}</p>
    <h2>{`画面遷移`}</h2>
    <p>{`画面遷移（プッシュ、モーダル、タブ遷移など）のために API として公式で提供されているのは iOS のみで Android は別途、実装するか、サードパーティのライブラリを使用する必要があります。`}</p>
    <p>{`わたしが使ってみた`}<a parentName="p" {...{
        "href": "https://wix.github.io/react-native-navigation/"
      }}>{`react-native-navigation`}</a>{`はモーダル、プッシュなどの遷移がネイティブ API ベースで実装されているので、ネイティブ言語で実装した場合と比べて違和感なく実装することができました。`}</p>
    <p>{`下記のような形でプッシュやモーダル表示での遷移ができます。特定の画面に戻る機能については開発中であったり、機能的な制約は少しありそうです。そういう細かいところはネイティブ言語でやった方が自由が効くので、良いなと思います。`}</p>
    <p>{`それでも、iOS と Android では複数画面の管理や遷移についての考え方が違い、Android を初めて開発した時に同じことを実現するのが難しかった覚えがあるので、共通の方法で実現できるのは便利でした。特に Web だとあまり画面間の遷移について考えることはないと思うので、共通化されているとネイティブアプリ開発の学習コストが下がると思います。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`navigator`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`push`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// プッシュ`}</span>{`

  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`screen`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"example.PushedScreen"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`title`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Pushed Screen"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`navigator`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`pop`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 前の画面に戻る`}</span>{`

  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`animated`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`animationType`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"fade"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`navigator`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`showModal`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// モーダル`}</span>{`

  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`screen`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"example.ModalScreen"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`title`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Modal"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`animationType`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"slide-up"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2>{`ネットワーク周り`}</h2>
    <p>{`ネットワーク経由でデータを取得して、モデルに変換し、アプリ内で使うというよくある操作を行うには Fetch API を利用します。`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/ja/docs/Web/API/Fetch_API"
      }}>{`Fetch API`}</a>{`は JS で提供されている Promise ベースの API です。例えば、以下のような形で JSON を返す API からデータを取得し、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`receiveHelthNews`}</code>{`関数にオブジェクトに変換した配列を渡すことができます。JS の API なので Web エンジニアにとっては使いやすいのではないかと思います。`}</p>
    <p>{`ネイティブ言語でそれぞれ実装する場合は、URLSession(iOS)と HttpURLConnection(Android)、あるいは各プラットフォーム向けに提供されているサードパーティのライブラリなどを使うと思いますが、当然、API は異なるのでそれぞれの実装方法を把握しないといけなくなります。それに比べると学習コストは低くなりそうです。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// js/actions/index.js`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fetchHelthNews`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`dispatch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fetch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`constructHealthNewsUrl`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`json`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`json`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`dispatch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`receiveHelthNews`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`json`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`articles`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`catch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`error`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`error`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`ネイティブアプリ特有の機能について`}</h2>
    <p>{`その他のアプリ開発でよく使うネイティブアプリ特有の機能を実装する方法は以下のようになります。多くの機能がサードパーティのライブラリに依存しているので、各言語のバージョンアップ時の対応が少し心配ではありますが、よく使う機能については実現することができます。`}</p>
    <ul>
      <li parentName="ul">{`Push 通知：Android はハンドリングする API が公式で提供されていないのでサードパーティのライブラリを使う`}</li>
      <li parentName="ul">{`カメラ、キーチェーンアクセス/ユーザデフォルト：公式の API は提供されていないのでサードパーティのライブラリを使う`}</li>
      <li parentName="ul">{`位置情報の取得：公式 API が提供されている`}</li>
      <li parentName="ul">{`ディープリンク：アプリ起動時にハンドリングを行う API は提供されている。ユニバーサルリンクや IntentFilter の設定は各プラットフォームで個別に必要になる`}</li>
    </ul>
    <h1>{`リリースはどうやるか`}</h1>
    <p>{`アーカイブやストア配布についてはネイティブアプリの配布と同じプロセスになります。`}</p>
    <p>{`各プラットフォームで証明書等の設定を行い、Xcode や Android Studio、あるいは CLI でコマンドを実行して、ipa / apk ファイルを作成し、各 Store にアップロードする必要があります。`}</p>
    <p>{`CI は`}<a parentName="p" {...{
        "href": "https://www.bitrise.io/"
      }}>{`Bitrise`}</a>{`などが使えます。Bitrise でビルドを試してみましたが、React Native のリポジトリと接続したときにできるデフォルトのワークフローを使えば、同時に 2 プラットフォームのアーカイブが作成できて便利でした。`}</p>
    <p>{`あと、まだ試せてはいないのですが`}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/appcenter/distribution/codepush/"
      }}>{`CodePush`}</a>{`を使えば、審査に提出することなしに既存のアプリを変更することもできるらしいので、非常に便利だと思いました。`}</p>
    <h1>{`どんな場合に React Native を採用できそうか?`}</h1>
    <p>{`React Native で開発することで、Web 開発者の視点で見るとプラットフォームのネイティブ言語で開発するよりも、だいぶ学習コストが下がるのではないかと思いました。またサードパーティのライブラリを使えば、機能的に大きな問題となるようなことはなさそうでした。ただ、画面遷移のライブラリがそうだったように、もしやりたいことができないという場合は、妥協しないといけない部分が出てきそうだと思いました。`}</p>
    <p>{`一方、アプリエンジニアにとっては、慣れるまではかなり開発速度が下がりそうなのでデメリットも大きいかなと思いました。React と JS、また Redux なども新たに理解しつつ開発していたので結構ハードルが高いと感じました。開発環境もビルドが通ってるうちは、View 周りの調整がすぐに確認できて良かったのですが、ランタイムエラーになるなどで、シミュレータがリロードできなくなった場合に再度ビルドし直すということがよく起こり、常に快適に開発できるというわけではありませんでした。`}</p>
    <p>{`運用面でみると一通りアプリ開発に必要そうなツールは揃っているし(クラッシュ監視、CI、テスト配信、リリース)、Code Push など便利なツールもあるので利点が多いと思いました。`}</p>
    <p>{`結論としては、Web エンジニアが社内に多かったり、開発チームに React、JS が得意なメンバーがいるなら、実際の開発でも使えるかなと思いました。ただ、アプリエンジニアにとってはかなりストレスがたまるプロジェクトになりそうだと感じました。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`自分の現状で考えると、アプリは得意だけど JS や React にそこまで詳しくないので、もし直近のプロジェクトで難易度もそこそこ高いようであれば、正直あんまり使いたくないなあという気持ちがあります。ただ、技術の幅を広げるとか、組織全体のポータビリティなどの観点で考えると利点が結構あるのかなと思います。チャンスがあればぜひ、チャレンジしてみたいです。`}</p>
    <p>{`わたしはどちらかというと技術や開発ツールの新しさよりも、ユーザとの接点のところで新しいことや面白いことを追求したいと思っていますが、開発効率や品質の向上のために最適なものを選択できるように、今後も新しいツールのキャッチアップは積極的に行っていきたいと思っています。`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーは、5/31-6/2 に開催される RubyKaigi 2018 に LT スポンサーとして協賛します。ブースも構えておりますので、イベントにお越しになる方は、ぜひブースにも遊びにいらしてください！`}</p>
    <p><a parentName="p" {...{
        "href": "https://rubykaigi.org/2018"
      }}>{`https://rubykaigi.org/2018`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      